import { IntercomUserData } from "@faro-lotv/foreign-observers";
import {
  CompanyRole,
  ProjectPermissions,
  RegisteredUserInfo,
} from "@faro-lotv/service-wires";
import { RootState } from "./store";

/**
 * @returns the current logged in user info if available or undefined if there's no logged in user
 * @param state app state
 */
export function selectCurrentUser(
  state: RootState,
): RegisteredUserInfo | undefined {
  return state.user.user;
}

/**
 * @returns true if the user is logged in and has write permission
 * @param state app state
 */
export function selectHasWritePermission(state: RootState): boolean {
  if (!state.user.user?.projectPermissions) return false;
  return state.user.user.projectPermissions.includes(ProjectPermissions.write);
}

/**
 * @returns True if the current user is allowed to buy tokens in the Dashboard
 * @param state Current app state
 */
export function selectIsAllowedToBuyTokens(state: RootState): boolean {
  return state.user.companyRole === CompanyRole.companyAdmin;
}

/**
 * @returns the current user's intercom info, if available
 * @param state app state
 */
export function selectIntercomInfo(
  state: RootState,
): IntercomUserData | undefined {
  return state.user.intercomInfo;
}
