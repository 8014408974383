import { IntercomUserData } from "@faro-lotv/foreign-observers";
import { clearStore } from "@faro-lotv/project-source";
import { CompanyRole, RegisteredUserInfo } from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type UserState = {
  /** This property is defined if the user is logged in, undefined for un-logged users */
  user?: RegisteredUserInfo;

  /**
   * The role of the user in the current company.
   * Undefined when not loaded yet, or when the current user has no role in the company of the current project.
   */
  companyRole?: CompanyRole;

  /**
   * The intercom info of the current user.
   * This is used to authenticate the current user in intercom.
   */
  intercomInfo?: IntercomUserData;
};

export const USER_SLICE_INITIAL_STATE: UserState = {};

/** Slice to store and manage the current user information */
const userSlice = createSlice({
  name: "user",
  initialState: USER_SLICE_INITIAL_STATE,
  reducers: {
    /**
     * Update the current user data stored in the slice
     *
     * @param state current state
     * @param action the new user info
     */
    setCurrentUser(state, action: PayloadAction<RegisteredUserInfo>) {
      state.user = action.payload;
    },

    /**
     * Update the role of the user in the current company
     *
     * @param state current state
     * @param action the role to assign
     */
    setCompanyRole(state, action: PayloadAction<CompanyRole | undefined>) {
      state.companyRole = action.payload;
    },

    /**
     * Updates the intercom info of the current user
     *
     * @param state current state
     * @param action the info to assign
     */
    setIntercomInfo(state, action: PayloadAction<IntercomUserData>) {
      state.intercomInfo = action.payload;
    },

    /**
     * Removes all user data from the store. Use e.g. when the user logs out.
     *
     * @returns the updated state
     */
    clearAllUserData(): UserState {
      return USER_SLICE_INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => USER_SLICE_INITIAL_STATE);
  },
});

export const userReducer = userSlice.reducer;

export const {
  setCurrentUser,
  setCompanyRole,
  setIntercomInfo,
  clearAllUserData,
} = userSlice.actions;
